type Error = {
    errors: { [key: string]: string };
}

interface FormError {
    [key: string]: string | null | void | any[any];
    clear(field: string): void;
    record({ errors }: Error): void;
}

class FormError implements FormError {
    /**
     * Create a new FormErrors instance.
     *
     * @param errors
     */
    constructor(fields = {}) {
        Object.keys(fields).forEach((field) => {
            this[field] = null;
        });
    }

    /**
     * Determine if the given field has a validation error.
     *
     * @param field
     */
    has(field: string) {
        return this[field] && this[field] !== null;
    }

    /**
     *  Count how many errors
     *
     *  @return Number
     */
    count() {
        let counter = 0;

        Object.keys(this).forEach((field) => {
            if (this.has(field)) {
                counter += 1;
            }
        });

        return counter;
    }

    /**
     * Get the error message of the given field.
     *
     * @param field
     * @returns {*}
     */
    get(field: string) {
        if (this[field]) {
            return Array.isArray(this[field]) && this[field].length === 1 ? this[field][0] : this[field];
        }

        return null;
    }

    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record({ errors }: Error) {
        if (errors) {        
            Object.keys(errors).forEach((key) => {
                this[key] = errors[key];
            });
        }
    }

    /**
     * Clear error on a specific field or all errors.
     *
     * @param field
     */
    clear(field: string) {
        if (this[field]) {
            this[field] = null;
            return;
        }

        if (!field) {
            Object.keys(this).forEach((key) => {
                this[key] = null;
            });
        }
    }
}

export default FormError;
