import Error from './formError';

interface Form {
    [key: string]: any;
}

class Form implements Form {    
    fields: {[key: string]: string | any[]};

    errors: Error;

    message: object;

    /**
     * Create a new Form instance.
     *
     * @param fields
     * @param name
     */
    constructor(fields: {[key: string]: any} = {}) {
        this.fields = fields;
        this.errors = new Error(fields);
        this.message = {};

        Object.keys(fields).forEach((key) => {
            this[key] = fields[key];
        });
    }

    payload() {
        const payload: {[key: string]: any} = {};

        Object.keys(this.fields).forEach((key) => {
            payload[key] = this[key];
        });

        return payload;
    }

    reset(fields: any) {
        if (typeof fields === 'string') {
            this[fields] = this.fields[fields];
            this.errors.clear(fields);

            return;
        }

        if (typeof fields === 'object') { 
            Object.keys(fields).forEach((field) => {
                this[field] = fields[field];
                this.errors.clear(field);
            });

            return;
        }

        Object.keys(this.fields).forEach((field) => {
            if (Array.isArray(this.fields[field])) {
                this[field] = [];
                this.fields[field] = [];
            } else {
                this[field] = this.fields[field];
            }

            this.errors.clear(field);
        });
    }

    set(object: {[key: string]: string}) {
        Object.keys(object).forEach((key) => {
            this[key] = typeof object[key] !== 'undefined' ? object[key] : this.fields[key];
        });
    }

    get(key: string) {
        return this[key];
    }

    has(key: string) {
        return this[key];
    }

    setMessage(message: null | object = null) {
        if (message !== null && typeof message === 'object') {
            this.message = message;
        }
    }

    resetMessage() {
        this.message = {};
    }
}

export default Form;
